<template>
  <div
    data-cy="payment-summary"
    class="PaymentSummary"
    :class="{
      'bg-primary-2-07 text-primary-1-100 rounded-3xl': inverted,
      'text-primary-1-100 ': !inverted,
    }"
  >
    <h2 class="px-6 text-xl-1 font-bold font-body">
      {{ $t('summary') }}
    </h2>

    <p class="flex items-center px-6">
      <span class="text-primary-1-60">{{ $t('subtotal') }}</span>
      <Money class="ml-auto" :value="subtotal" />
    </p>

    <slot name="extensions" />

    <p v-if="paymentFees?.fees || paymentFees?.fees > 0" class="flex items-center px-6">
      <span class="text-primary-1-60">{{ paymentFees.title }}</span>
      <Money class="ml-auto text-base" :value="paymentFees.fees" />
    </p>
    <p v-if="shippingFees || shippingFees === 0" class="flex items-center px-6">
      <span class="text-primary-1-60">{{ $t('shippingFees') }}</span>
      <Money class="ml-auto text-base" :value="shippingFees" />
    </p>
    <p v-else class="flex items-center px-6">
      <span class="text-primary-1-60">{{ $t('shippingFees') }}</span>
      <Money class="ml-auto text-base" :value="shippingFees" :fallback="$t('toBeCalculated')" />
    </p>

    <template v-if="discounts && discounts.length">
      <p v-for="({ label, value }, index) in mapDiscountLabels()" :key="index" class="flex items-center px-6">
        <span class="text-primary-1-60">{{ label }}</span>
        <Money class="ml-auto" :value="value * -1" :fallback="$t('toBeCalculated')" />
      </p>
    </template>

    <p
      v-if="giftCardDetails && giftCardDetails.discounted && giftCardDetails.giftCardCode"
      class="flex items-center px-6"
    >
      <span class="text-primary-1-60">{{ $t('giftCardDetails', { code: giftCardDetails.giftCardCode }) }}</span>
      <Money class="ml-auto text-base" :value="giftCardDetails.discounted * -1" />
    </p>

    <p v-if="loyaltyVoucher && loyaltyVoucher.discount && loyaltyVoucher.code" class="flex items-center px-6">
      <span class="text-primary-1-60">{{ $t('loyaltyVoucher', { code: loyaltyVoucher.code }) }}</span>
      <Money class="ml-auto text-base" :value="loyaltyVoucher.discount * -1" />
    </p>

    <div class="mx-6 bg-primary-2-02 h-unit"></div>

    <p class="flex items-center px-6">
      <span class="text-primary-1-60">{{ $t('total') }}</span>
      <Money class="ml-auto font-bold" :value="total" />
    </p>

    <slot name="cta-placeholder" />

    <slot name="footer" />
  </div>
</template>

<script setup lang="ts">
import type { AppliedDsquareLoyality } from '~/graphql-types.gen';

defineComponent({
  name: 'PaymentSummary',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  total: {
    type: Number,
    default: 0,
  },
  subtotal: {
    type: Number,
    default: 0,
  },
  shippingFees: {
    type: Number,
    default: () => null,
  },
  hasInvalidItem: {
    type: Boolean,
    default: false,
  },
  discounts: {
    type: Array as PropType<Array<{ label: string; value: number }>>,
    default: () => [],
  },
  inverted: {
    type: Boolean,
    default: false,
  },
  giftCardDetails: {
    type: Object as PropType<{ discounted: number; giftCardCode: string }>,
    default: () => ({}),
  },
  paymentFees: {
    type: Object as PropType<{
      title: string;
      fees: number;
    }>,
    default: null,
  },
  loyaltyVoucher: {
    type: Object as PropType<{
      code: AppliedDsquareLoyality['code'];
      discount: AppliedDsquareLoyality['applied_discount'];
    }>,
    default: () => ({}),
  },
});

const { t: $t } = useI18n({ useScope: 'local' });

const discountLabels: Record<string, string> = {
  Discount: 'discount',
  'Gift Card': 'giftCardDetails',
  'Loyalty Voucher': 'loyaltyVoucher',
};

function mapDiscountLabels() {
  return props.discounts.map(({ label, value }) => {
    const matchingKey = Object.keys(discountLabels).find(key => label.startsWith(key));
    const mappedLabel = matchingKey ? label.replace(matchingKey, $t(discountLabels[matchingKey])) : label;

    return {
      label: mappedLabel,
      value,
    };
  });
}
</script>

<style lang="postcss" scoped>
.PaymentSummary {
  @apply md:self-start flex flex-col;
  top: 30px;
  row-gap: 20px;
  padding-block: 30px;

  .disable-checkout {
    @apply opacity-50;
    pointer-events: none;
  }
}
</style>

<i18n>
{
  "en": {
    "summary": "Payment Summary",
    "subtotal": "Subtotal",
    "total": "Total Inclusive of VAT",
    "shippingFees": "Shipping Fees",
    "taxesIncluded": "All taxes are included",
    "proceed": "Proceed to Checkout",
    "toBeCalculated": "To Be Calculated",
    "eligibleForFreeShipping": "Eligible for free shipping",
    "giftCardDetails" : "Gift Card {code}",
    "loyaltyVoucher" : "Loyalty Voucher {code}",
    "discount" : "Discount"
  },
  "ar": {
    "summary": "تفاصيل الطلب",
    "subtotal": "الاجمالي الفرعي",
    "total": "الاجمالي يشمل قيمه الضرائب",
    "shippingFees": "مصاريف الشحن",
    "taxesIncluded": "يشمل قيمه الضرائب",
    "proceed": "المتابعه للدفع",
    "toBeCalculated": "سيتم حسابها لاحقا",
    "eligibleForFreeShipping": "شحن مجاني",
    "giftCardDetails" : "بطاقه الهدايا {code}",
    "loyaltyVoucher" : "قسيمة ولاء {code}",
    "discount" : "خصم"

  }
}
</i18n>
